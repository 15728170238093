.c-stars{
    width:100%;
    height:100%;
    position:absolute;
    top:0px;
    left:0px;
    display:none;
    .app--active &{
        display:block;
    }
    
    &__star{
        fill:#fff;
        width:1.5px;
        height:1.5px;
        &--blink{
            animation: starBlink 3s linear 0s infinite;
        }
    }
}


@keyframes starBlink{
    50%{
        opacity:0;
    }
}
