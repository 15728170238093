.c-title{
    cursor:pointer; 
    font-family: Impact, "Coda Caption", sans-serif;
    margin:0 auto;
    font-weight: bold;
    font-size:11vw;
    letter-spacing: -0.04em;
    display:flex;
    justify-content: center;
    z-index:20;
  
    animation: none 6s linear 4s infinite;
  
    // .app--active &{
      // animation-name : titleFlip;
    // }

    
    &__letter{
        flex-shrink:0;
        display:block;
        position: relative;
        
        color:$baseColor;
        -webkit-text-stroke:1px $baseShadowColor;
      
        animation: none 2.4s linear 0s infinite;
      
        &:before{
          content: attr(data-letter);
          display: block;
          position: absolute;
          top: -10px;
          z-index: -1;
          color: $baseShadowColor;
          left: 8px;
          animation: none 2.4s linear 0s infinite;
        }
      
        .app--active &{
          animation-name: letterJump, colorChange;
          -webkit-text-stroke:1px #000;
          &:before{
            animation-name: letterJumpBefore, colorChangeBefore;
          }
        }
      
        &:empty{
          width:0.2em;
        }
      
        @for $i from 0 through 15{
          &:nth-child(#{$i - 1}) { 
            &,&:before{
              animation-delay: 
                #{$i * 0.2}s,
                #{random()*3}s; 
            }
          }
        }
      }
}

@keyframes titleFlip{
    0%{
        transform:rotateY(0deg);
    }
    10%{
        transform:rotateX(360deg);
    }
    90%{
        transform:rotateX(360deg);
    }
    100%{
        transform:rotateX(0deg);
    }
}

@keyframes letterJump {
    50% {
        transform:translateY(-50px);
    }
}
@keyframes letterJumpBefore {
    50% {
        top:-1px;
    }
}
@keyframes colorChange{
    0%, 19% {
        color:red;
    }
    20%, 39% {
        color:green;
    }  
    40%, 59% {
        color:yellow;
    }   
    60%, 79% {
        color:blue;
    }  
    80%, 100% {
        color:magenta;
    }
}
@keyframes colorChangeBefore{
    0% {
        color:darken(red, 20);
    }
    19% {
        color:darken(red, 20);
    }  
    20% {
        color:darken(green, 20);
    }  
    39% {
        color:darken(green, 20);
    }  
    40% {
        color:darken(yellow, 20);
    } 
    59% {
        color:darken(yellow, 20);
    }   
    60% {
        color:darken(blue, 20);
    }  
    79% {
        color:darken(blue, 20);
    }  
    80% {
        color:darken(magenta, 20);
    }
    100% {
        color:darken(magenta, 20);
    }
}