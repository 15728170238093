$baseDelay: 12s;
$particles: 50;
$width: 500;
$height: 500;

$box-shadow: ();
$box-shadow2: ();
@for $i from 0 through $particles {
    $box-shadow: $box-shadow,
        random($width)-$width / 2 + px
        random($height)-$height / 1.2 + px
        gold;
        $box-shadow2: $box-shadow2, 0 0 #fff
}

.c-fireworks{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    display:none;

    .app--active &{
        display:flex;
    }
    &:before, 
    &:after {
        content:'';
        position: absolute;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        box-shadow: $box-shadow2;
        animation: 
            1s bang ease-out infinite backwards, 
            1s gravity ease-in infinite backwards, 
            5s position linear infinite backwards;

        animation-delay:$baseDelay;
    }

    &:after {
        animation-delay: #{$baseDelay + 1.25s}, #{$baseDelay + 1.25s}, #{$baseDelay + 1.25s};
        animation-duration: 1.25s, 1.25s, 6.25s;
    }
        
    @keyframes bang {
        to {
            box-shadow:$box-shadow;
        }
    }

    @keyframes gravity  {
        to {
            transform: translateY(200px);
            opacity: 0;
        }
    }

    @keyframes position {
        0%, 19.99% {
            top: 30%;
            left: 40%;
        }
        20%, 39.99% {
            top: 40%;
            left: 30%;
        }
        40%, 59.99% {  
            top: 50%;
            left: 70%
        }
        60%, 79.99% {  
            top: 50%;
            left: 20%;
        }
        80%, 100% {  
            top: 30%;
            left: 80%;
        }
    }

}