html,body{
    height:100%;
    width:100%;
}
.app{
    height:100vh;
    width:100vw;
    display:flex;
    justify-content: center;
    align-items: center;
    background:#c0c0c0;
    transition:all 1s ease-out;
    &--active{
        background:#000;
    }
}